// .section-about {
//  background-image: linear-gradient(
//         to right bottom,
//         rgba($color-primary-light, 0.8),
//         rgba($color-primary-dark, 0.8)),
//     url(../../static/owners.jpg);
//     padding: 20rem 0;
//     margin-top: 20vh;

//     @include respond(tab-port) {
//         padding: 20rem 0;
//     }
//     background-size: cover;
//     background-position: 25%;
//     transform: skewY(-7deg);
//     margin-top: -10rem;

//     & > * {
//         transform: skewY(7deg);
//     }

//     @include respond(tab-port) {
//         padding: 10rem 0;
//     }
// }

.section-look {
  background-color: $color-white;
  & > .button {
    z-index:20;
  }
  // @include respond(phone) {
  //   padding:22rem 0;
  // }
  .takealook {
    margin-bottom: 0rem;
  }    
}

.section-about {
    padding: 20rem 0;
    // background-image: linear-gradient(
    //     to right bottom,
    //     rgba($gunmetal, 0.8) 25%,
    //     rgba($color-grey-dark-2, 0.8)),
    //     url(../../static/owners.jpg);
    background-image: linear-gradient(
      to bottom,
      rgba($color-grey-dark-2, .8) 15%,
      rgba($gunmetal, 1)),
      url(../../static/owners.jpg);
    background-size: cover;
    background-position: 25%, 35%;
    @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% $header_clip_height, 0 100%);
      clip-path: polygon(0 20vh, 100% 0, 100% 51vh, 0 100%);
    }


    & > * {
        transform: skewY(0deg);
    }
    &__half {
      margin-left:auto;
      margin-right: auto;
      width: 60%;
    }
    .heading-secondary {
      color: $color-white;
      margin-bottom: 3rem;
    }
    .paragraph {
      color: $color-white;
      letter-spacing: .2rem;
    }
    @include respond(iphone10) {
      margin-top:-17vh;
      background-position:54% 35%;
      -webkit-clip-path: polygon(0 0, 100% 0, 100% $header_clip_height, 0 100%);
      clip-path: polygon(0 5vh, 100% 0, 100% 100%, 0 100%);
    }
    @include respond(iphone10-land) {
      margin-top:-100vh;
      background-position: 54% 35%;
      // background-image: linear-gradient(
      //   to bottom,
      //   rgba($color-grey-dark-2, .8) 15%,
      //   rgba($gunmetal, 1)),
      //   url(../../static/owners.jpg);
    }
    @include respond(iphone678) {
      margin-top:0vh;
      -webkit-clip-path: polygon(0 0, 100% 0, 100% $header_clip_height, 0 100%);
      clip-path: polygon(0 5vh, 100% 0, 100% 100%, 0 100%);
      background-position:54%;
    }
    @include respond(mdpi) {
      margin-top:-30vh;
      background-position:54% 35%;

    }    
    @include respond(hires) {
      margin-top:-25vh;
      padding-top:16vh;
    }

    @include respond(tab-port) {
        padding: 10rem 0;
    }
    @include respond(dpi4){
      margin-top:-40vh;
      background-position:54%;
    }


}

.section-tours {
    background-color: $color-grey-light-1;
    padding: 20rem 0 15rem 0;
    margin-top: -25rem;

    @include respond(tab-port) {
        padding: 20rem 0 10rem 0;
    }
}

.section-stories {
    position: relative;
    padding: 15rem 0;

    @include respond(tab-port) {
        padding: 10rem 0;
    }
}

.section-book {
    padding: 15rem 0;
    background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark);

    @include respond(tab-port) {
        padding: 10rem 0;
    }
}

.book {
    // background-image: linear-gradient(105deg, 
    //                                 rgba($color-white, .9) 0%,
    //                                 rgba($color-white, .9) 50%,
    //                                 transparent 50%) ,
    //                         url(../img/nat-10.jpg);
    background-size: 100%;
    border-radius: 3px;
    box-shadow: 0 1.5rem 4rem rgba($color-black, .2);

    @include respond(tab-land) {
//         background-image: linear-gradient(105deg, 
//         rgba($color-white, .9) 0%,
//         rgba($color-white, .9) 65%,
//         transparent 65%) ,
// url(../img/nat-10.jpg);
        background-size: cover;
    }

    @include respond(tab-port) {
//         background-image: linear-gradient(to right, 
//         rgba($color-white, .9) 0%,
//         rgba($color-white, .9) 100%),
// url(../img/nat-10.jpg);
    }

    &__form {
        width: 50%;
        padding: 6rem;

        @include respond(tab-land) {
            width: 65%;
        }

        @include respond(tab-port) {
            width: 100%;
        }
    }
}